import React from 'react';
import './style.css';
import { FaGithub, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { socialprofils } from '../../content_option';

export const Socialicons = (params) => {
  return (
    <div className='stick_follow_icon'>
      <ul>
        {socialprofils.twitter && (
          <li>
            <a href={socialprofils.twitter} target='_blank' rel='noreferrer'>
              <FaTwitter />
            </a>
          </li>
        )}
        {socialprofils.github && (
          <li>
            <a href={socialprofils.github} target='_blank' rel='noreferrer'>
              <FaGithub />
            </a>
          </li>
        )}
        {socialprofils.linkedin && (
          <li>
            <a href={socialprofils.linkedin} target='_blank' rel='noreferrer'>
              <FaLinkedin />
            </a>
          </li>
        )}
        {socialprofils.instagram && (
          <li>
            <a href={socialprofils.instagram} target='_blank' rel='noreferrer'>
              <FaInstagram />
            </a>
          </li>
        )}
      </ul>
      <p>Follow Me</p>
    </div>
  );
};
